import React from "react";
import Card from "../Card";
import cardBackImg from '../../img/cardBackImg.jpeg';
import './index.css';
import classNames from "classnames";

const CardsOnHands = ({
                          name,
                          countCardsBlitz,
                          cardLastBlitz,
                          cardOnTable,
                          cardOnTableDeck,
                          isYou = false,
                          position,
                          handleClickOnDeck,
                          handleClickOnCard
                      }) => {
    // const {sendMessage} = useContext(WebSocketContext);

    // const handleClick = isYou ? handleClickFunc : null;
    // console.log('handleClick', handleClick);
    const handleClick = (id) => {
        if (typeof handleClickOnDeck === "function") {
            console.log('click')
            handleClickOnCard(id);
        }
    }
    return (
        <div className={`player-${position} cardsOnHands`}>
            <p>Player name: {name} {isYou && "(You)"}</p>
            {/* карты в колоде блиц */}
            <span className="blitzDeck">
                <p>Your cards {countCardsBlitz} of 10:</p>
                <Card id={cardLastBlitz.id}
                      cardColor={cardLastBlitz.color}
                      cardNumber={cardLastBlitz.number}
                      isYou={isYou}
                      handleClick={isYou ? () => handleClick(cardLastBlitz.id) : null}
                />
            </span>

            {/* Карты на столе из колоды блиц */}
            <span className="blitzOnTable">
                {cardOnTable.map(card => <Card key={card.id}
                                               id={card.id}
                                               cardColor={card.color}
                                               cardNumber={card.number}
                                               isYou={isYou}
                                               handleClick={isYou ? () => handleClick(card.id) : null}
                    // handleClick={() => handleClick('test')}
                />)}
            </span>

            <span className="deckCards">
                {/* Карты в деке */}
                {cardOnTableDeck && cardOnTableDeck?.id &&
                    <Card id={cardOnTableDeck.id}
                          cardColor={cardOnTableDeck.color}
                          cardNumber={cardOnTableDeck.number}
                          isYou={isYou}
                          handleClick={isYou ? () => handleClick(cardOnTableDeck.id) : null}
                    />
                }

                {/*Картинка деки*/}
                <img alt='deckCardShirt' src={cardBackImg} className={classNames('deckCardShirt', isYou ? '' : 'small',)}
                     onClick={isYou ? () => handleClickOnDeck() : null} style={isYou ? { cursor: "pointer" } : {}}/>
            </span>
        </div>
    )
}

export default CardsOnHands;

