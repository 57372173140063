import React, {useContext, useEffect, useState} from 'react';
import Table from "../Table";
import CardsOnHands from "../CardsOnHands";
import './index.css';
import { useParams } from 'react-router-dom';
import {useWebSocketContext} from "../../contexts/WebSocketContext";
import {UserContext} from "../../contexts/UserContext";
import Score from "../Score";
import ActionsButtons from "../ActionsButtons";


const positionList = ['top', 'left', 'right'];

const Game = () => {
    const { sendMessage, lastMessage } = useWebSocketContext();
    const { room, userName } = useContext(UserContext);
    console.log('room, user', room, userName)



    const [data, setData] = useState(null);
    const [usersOnline, setUsersOnline] = useState(null);
    useEffect(() => {
        sendMessage({action: "connectToRoom", params: {roomToken: room, name: userName}})
    }, []);

    useEffect(() => {
        console.log('lastMessage')
        if (lastMessage && lastMessage?.action && lastMessage.action === 'updateCards') {
            setData(lastMessage?.data);
            return;
        }

        if (lastMessage && lastMessage?.action && lastMessage.action === 'endGame') {
            setData({...lastMessage?.data, endGame: true})
            return;
        }

        if (lastMessage && lastMessage?.action && lastMessage.action === 'playersOnline') {
            setUsersOnline(lastMessage?.message);
        }
    }, [lastMessage])

    const startRoom = () => {
        sendMessage({action: "start", params: {roomToken: room, name: userName}})
    }

    const clickOnDeck = () => {
        sendMessage({action: "clickDeck", params: {}})
    }

    const clickOnCard = (cardId) => {
        sendMessage({action: "clickCard", params: {cardId}})
    }

    const mixingCards = () => {
        sendMessage({action: "mixingCards", params: {}})
    }

    return (
        <div>
            {/*<Header restartGame={this.restartGame} />*/}
            {!data && <div>
                <button onClick={startRoom}>START</button>

                <ul>
                    {usersOnline && usersOnline.map((user, ids) => <li key={ids}>{user.name}</li>)}
                </ul>
            </div>}
            {data && <div className="game-table">
                {data?.other.map((playerInfo, ids) => <CardsOnHands
                    key={ids}
                    cardLastBlitz={playerInfo?.cardLastBlitz}
                    countCardsBlitz={playerInfo?.countCardsBlitz}
                    cardOnTable={playerInfo?.cardOnTable}
                    cardOnTableDeck={playerInfo?.cardOnTableDeck}
                    name={playerInfo?.name}
                    position={positionList[ids]}
                />)}

                <Table cards={data?.table}/>

                <div className='bottom-row'>
                    <CardsOnHands
                        position={'bottom'}
                        cardLastBlitz={data.main?.cardLastBlitz}
                        countCardsBlitz={data.main?.countCardsBlitz}
                        cardOnTable={data.main?.cardOnTable}
                        cardOnTableDeck={data.main?.cardOnTableDeck}
                        name={data.main?.name}
                        isYou={true}
                        handleClickOnDeck={clickOnDeck}
                        handleClickOnCard={clickOnCard}
                    />
                    <Score
                        position={'bottom-right'}
                        scores={data?.score}
                    />
                    <ActionsButtons mixCards={mixingCards} startNewGame={startRoom} isGameEnd={data?.endGame} />
                </div>
            </div>}
        </div>
    );
}

export default Game;