import React from "react";
import "./index.css"
import {button} from "../Home/index.css"


const ActionsButtons = ({mixCards, startNewGame, isGameEnd}) => {

    return (
        <div className="actions">
            <button className="button" onClick={mixCards}>Перемешать карты</button>
            <button className="button" onClick={startNewGame} disabled={!isGameEnd}>Начать новую игру</button>
        </div>
    )
}

export default ActionsButtons;