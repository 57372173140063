import React from "react";
import "./index.css"

const Score = ({scores = []}) => {

    return (
        <div className="score">
            {/*{scores?.length === 0 && <div className='noCardMessage'>На столе пока нет карт</div>}*/}
            <table>
                <thead>
                    <tr>
                        <th>Имя</th>
                        <th>Счет</th>
                    </tr>
                </thead>
                <tbody>
                    {scores.map((score, ids) =>
                        <tr key={ids}>
                            <td>{score?.name}</td>
                            <td>{score?.point} {score?.totalPoint && `(${score?.totalPoint})`}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default Score;