import React from 'react';
import classNames from "classnames";
import './index.css'

const Card = ({
                  id, cardColor, cardNumber, handleClick = null, isYou = false
              }) => (
    <div className={classNames('card', isYou ? '' : 'small', handleClick === null ? '' : 'isClickable')}
         id={id}
         style={{backgroundColor: cardColor, color: cardColor === 'yellow' ? 'black' : 'white'}}
         onClick={handleClick}
    >
        <div className="number">{cardNumber}</div>
    </div>
);

export default Card;
