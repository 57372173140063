import React, {useContext, useEffect, useState} from "react";
import useWebSocket from "react-use-websocket";
import {UserContext} from "./UserContext";

const WebSocketContext = React.createContext();

const generateRandomString = (length = 32) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const WebSocketProvider = ({ children }) => {
    const defaultUrl = process.env.REACT_APP_WS_URL;
    const [param, setParam] = useState({});
    const { token, setToken } = useContext(UserContext);

    const { getWebSocket: socket, sendJsonMessage, lastJsonMessage } = useWebSocket(defaultUrl, { share: true, queryParams: param });

    useEffect(() => {
        const randomToken = generateRandomString();
        setToken(randomToken);
        setParam({token: randomToken})
    }, [])

    useEffect(() => {
        // if (lastJsonMessage?.status === true && lastJsonMessage?.token) {
        //     setToken(lastJsonMessage.token)
        //     setParam({token: lastJsonMessage.token})
        //
        //     // здесь вы можете выполнить свою логику обработки входящего сообщения
        //     // например, добавить его в список полученных сообщений
        //
        // }
    }, [lastJsonMessage]);

    const sendMessage = (message) => {
        if (typeof message === "object" && message['params']) {
            message['params']['token'] = token;
        }
        sendJsonMessage(message);
    }
    //

    return (
        <WebSocketContext.Provider value={{ socket, sendMessage, lastMessage: lastJsonMessage, setParam }}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocketContext = () => {
    const context = useContext(WebSocketContext);
    if (context === undefined) {
        throw new Error(
            "useWebSocketContext must be used within a WebSocketProvider"
        );
    }
    return context;
};
