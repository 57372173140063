import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {UserProvider} from "./contexts/UserContext";
import {WebSocketProvider} from "./contexts/WebSocketContext";
import Home from "./components/Home";
import Game from "./components/Game";
// import Nav from './components/Nav';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <UserProvider>
            <WebSocketProvider>
                <BrowserRouter>
                    {/*<App/>*/}
                    {/*<Nav/>*/}
                    <Routes>
                        <Route
                            path={'/'}
                            exact={true}
                            element={<Home/>}
                        />
                        <Route
                            path={'/room/:roomId/:name'}
                            element={<Game/>}
                        />
                    </Routes>
                </BrowserRouter>
            </WebSocketProvider>
        </UserProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
