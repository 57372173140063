import React, {createContext, useState} from "react";

export const UserContext = createContext(null);

export const UserProvider = ({children}) => {
    const [userName, setUserName] = useState(localStorage.getItem('userName'));
    const [room, setRoom] = useState(localStorage.getItem('room'));
    const [token, setToken] = useState(localStorage.getItem('token'));
    const setCurrentRoom = (roomId) => {
        localStorage.setItem('room', roomId);
        setRoom(roomId);
    }
    const setCurrentUser = (userName) => {
        localStorage.setItem('userName', userName);
        setUserName(userName);
    }
    const setCurrentToken = (token) => {
        localStorage.setItem('token', token);
        setToken(token);
    }
    const setData = (data) => {
        Object.keys(data).forEach(key => {
            localStorage.setItem('key', data[key]);
        })
    }

    return (
        <UserContext.Provider value={{
            userName,
            setUserName: setCurrentUser,
            room,
            setRoom: setCurrentRoom,
            setData,
            token,
            setToken: setCurrentToken
        }}>
            {children}
        </UserContext.Provider>
    );
};
