import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { useWebSocketContext } from "../../contexts/WebSocketContext";
import {createRoom as create} from "../../utils/api";
import "./index.css"

const Home = () => {
    const { userName = '', setRoom, setUserName, token } = useContext(UserContext);
    const [name, setName] = useState(userName);
    const [roomId, setRoomId] = useState("");
    const navigate = useNavigate();
    const { sendMessage, setParam } = useWebSocketContext();
    const createRoom = async () => {
        const {roomId} = await create(token);
        setRoomId(roomId);
    };

    const connectToRoom = () => {
        if (name && roomId) {
            setRoom(roomId);
            setUserName(name);
            // setParam({name, roomId})
            // sendMessage(JSON.stringify({ type: "connect", name, roomId }));
            navigate(`/room/${roomId}/${name}`);
        }
    };

    return (
        <div className='container'>
            <h1 className='title'>Welcome to the game!</h1>
            <div style={{ marginBottom: "1rem" }}>
                <button onClick={createRoom} className='button'>Create room</button>
            </div>
            <div className='container'>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Name"
                    className="input"
                />
                <input
                    type="text"
                    value={roomId}
                    onChange={(e) => setRoomId(e.target.value)}
                    placeholder="Room Id"
                    className="input"
                />
                <button onClick={connectToRoom} className="button">Connect to room</button>
            </div>
        </div>
    );
};

export default Home;
