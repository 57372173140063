const API_URL = process.env.REACT_APP_API_URL;

export const createRoom = async (token) => {
    const response = await fetch(`${API_URL}/create?token=${token}`);
    const data = await response.json();
    console.log('response', response)
    console.log('data', data)
    return data;
}

export const joinRoom = async (roomId, name) => {
    const response = await fetch(`${API_URL}/joinRoom?roomId=${roomId}&name=${name}`);
    const data = await response.json();
    return data.success;
}
