import React from "react";
import "./index.css"
import Card from "../Card";


const Table = ({cards = []}) => {

    return (
        <div className="table">
            {cards?.length === 0 && <div className='noCardMessage'>На столе пока нет карт</div>}
            {cards.map((card, ids) =>
                <Card key={ids}
                      cardNumber={card.number}
                      cardColor={card.color}
                      id={card.id}
                      isYou
                />)}
        </div>
    )
}

export default Table;